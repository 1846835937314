import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

class ProjectListCarousel extends React.Component {
  constructor() {
    super();
  }

  renderItem = (item, idx) => {
    const { photos, projectId, linkText } = this.props;
    const isLast = photos.length - 1 === idx;

    return (
      <div className="image-gallery-image">
        <img src={item.original} />
        {isLast &&
          <a href={`/projects/${projectId}`} className="more-link">{linkText}</a>
        }
      </div>
    )
  }

  getImages = () => {
    const { photos, originalVersion } = this.props;

    return photos.map((image, idx) => ({
      original: image.url[originalVersion].url,
      originalAlt: image.alt,
      renderItem: (item) => this.renderItem(item, idx)
    }))
  }

  render() {
    return (
      <div className="gallery-carousel-wrap">
        <ImageGallery items={this.getImages()}
          ref={i => this._imageGallery = i}
          lazyLoad
          useTranslate3D={false}
          showPlayButton={false}
          slideInterval={4000}
          showFullscreenButton={false}
          showThumbnails={false}
          infinite={false}
        />
      </div>
    );
  }
}


ProjectListCarousel.propTypes = {
  photos: PropTypes.array,
  originalVersion: PropTypes.string,
  projectId: PropTypes.number,
  linkText: PropTypes.string
};

export default ProjectListCarousel;
