const downloadJSAtOnload = () => {
  loadFonts();
  let externalJs = [
  ]
  externalJs.forEach(addScript);
}

const loadFonts = () => {
  WebFontConfig = {
    google: {
      families: [
        'Open Sans:300,400,600,700&display=swap&subset=cyrillic']
    },
    custom: {
      families: ['Ds-StandartCyr'],
      urls: ['/assets/fonts.css']
    },
    active: () => {
      sessionStorage.fonts = true;
    }
  };
  ((d) => {
    let wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.async = true;
    s.parentNode.insertBefore(wf, s);
  })(document);
}

function addScript(element, index, array) {
  let script = document.createElement('script');
  script.src = element;
  document.body.appendChild(script);
}

if (window.addEventListener)
  window.addEventListener('load', downloadJSAtOnload, false);
else if (window.attachEvent)
  window.attachEvent('onload', downloadJSAtOnload);
else window.onload = downloadJSAtOnload;
