import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import ProjectListCarousel from './ProjectListCarousel';
import InfiniteScroll from './InfiniteScroll';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      page: 1,
      total: 0
    };
  }

  componentDidMount(){
    this.fetchProjects();
  }

  fetchProjects = () => {
    const {projects, page} = this.state;

    fetch(`/api/v1/projects.json?page=${page}`)
      .then((response) => {return response.json()})
      .then((data) => {
        this.setState({
          projects: [...projects, ...data.projects],
          page: page + 1,
          total: data.total
        })
      });
  }

  loadMore = () => {
    console.log('test');
    this.fetchProjects();
  }

  renderProject = (project) => {
    const {linkText} = this.props;

    return (
      <div className="project" key={project.id}>
        <a href={`/projects/${project.id}`} className="container-fluid">
          <h2>{project.title}</h2>
          <div className="project-description">
            <h3>{project.short_description}</h3>
            <span className="size">{project.size_room} М 2</span>
          </div>
        </a>
        <ProjectListCarousel
          projectId={project.id}
          linkText={linkText}
          originalVersion="large"
          photos={project.photos}
        />
      </div>
    )
  }

  render() {
    const { projects, total } = this.state;

    return (
      <div className="project-list">
        {projects.map(this.renderProject)}
        {projects.length < total &&
          <InfiniteScroll  root=".project-list" onVisible={this.loadMore} />
        }
      </div>
    );
  }
}


ProjectList.propTypes = {
  isMobile: PropTypes.bool,
  projects: PropTypes.array
};

export default ProjectList;
