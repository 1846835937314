import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

class InfiniteScroll extends React.Component {
  constructor(prop) {
    super(prop);
    this.lastRef = React.createRef();
  }

  componentDidMount() {
    this.rootElem = document.querySelector(`${this.props.root}`);
    if (!this.lastRef.current) return;
    this.observer = new IntersectionObserver(this.interSectionCallback, {
      threshold: [1]
    });
    this.observer.observe(this.lastRef.current);
  }

  interSectionCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio === 1) {
        this.props.onVisible();
      }
    });
  };

  render() {
    return (
      <div ref={this.lastRef} className="spinner-wrap">
        <Spinner />
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  root: PropTypes.string.isRequired,
  onVisible: PropTypes.func
};

InfiniteScroll.defaultProps = {
  onVisible: () => {}
};

export default InfiniteScroll;
