import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

class ProjectCarousel extends React.Component {
  constructor() {
    super();
  }

  renderLargeItem = (item) => (
    <div className="image-gallery-image main_image">
      <img src={item.original} />
      <div className="container-fluid">
        <img src={item.original} />
      </div>
    </div>
  )

  renderMobileItem = (item) => (
    <div className="image-gallery-image">
      <img src={item.original} />
    </div>
  )

  getImages = () => {
    const { photos, originalVersion, isMobile } = this.props;

    return photos.map((image) => ({
      thumbnail: image.url.small.url,
      original: image.url[originalVersion].url,
      originalAlt: image.alt,
      renderItem: isMobile ? this.renderMobileItem : this.renderLargeItem
    }))
  }

  slideThumbs = (back) => {
    const current = this._imageGallery.getCurrentIndex();
    const next = back ? current - 4 : current + 4;
    this._imageGallery.slideToIndex(next);
  }

  render() {
    return (
      <div className="gallery-carousel-wrap">
        <ImageGallery items={this.getImages()}
          ref={i => this._imageGallery = i}
          lazyLoad
          // autoPlay
          useTranslate3D={false}
          showPlayButton={false}
          slideInterval={4000}
        />
        {/* <button className="image-gallery-left-nav thumb-nav"
          onClick={() => {this.slideThumbs(true)}}></button>
        <button className="image-gallery-right-nav thumb-nav"
          onClick={() => {this.slideThumbs()}}></button> */}
      </div>
    );
  }
}


ProjectCarousel.propTypes = {
  isMobile: PropTypes.bool,
  photos: PropTypes.array,
  originalVersion: PropTypes.string
};

export default ProjectCarousel;
